<template>
  <div class="product">
    <div class="aft_banner">
      <img :src="$host + banner.litpic" alt="" />
      <!-- <img :src="$host + banner.litpicm" alt="" /> -->
    </div>
    <div class="selectToggle">
      <div class="box flexCenter m160">
        <p class="active">
          <router-link to="/product/brand">冠军象</router-link>
        </p>
        <p><router-link to="/product/productInfo">本宫月记</router-link></p>
      </div>
    </div>
    <div v-show="isOut">
      <div class="aft_title p80 top1">
        <div class="box">
          <p>{{ ppgs.englist_name }}</p>
          <div>{{ ppgs.typename }}</div>
        </div>
      </div>
      <div class="productBox">
        <div class="box1 m160">
          <p style="white-space: pre-line">
            {{ ppgs.tdesc }}
          </p>
        </div>
        <div class="box2">
          <p class="m160"><img :src="$host + ppgs.img" alt="" /></p>
        </div>
        <div class="box3">
          <div class="m160">
            <p style="white-space: pre-line">{{ ppgs.bdesc }}</p>
          </div>
        </div>
      </div>
      <div class="aft_title p80">
        <div class="box">
          <p>{{ pp_video.englist_name }}</p>
          <div>{{ pp_video.typename }}</div>
        </div>
      </div>
      <div class="proVideo">
        <div class="box m160">
          <div class="video">
            <span @click="videHide" v-show="!videoState">x</span>
            <video
              :src="$host + pp_video.video"
              ref="proVideo"
              controls
            ></video>
            <img :src="$host + pp_video.img" alt="" v-show="videoState" />
          </div>

          <template v-if="pp_video.video">
            <div
              class="play"
              v-show="videoState"
              @click="videPlay"
              :title="pp_video.video"
            >
              <img src="../assets/images/play.png" alt="" />
            </div>
          </template>
        </div>
      </div>
      <div class="aft_title p80 top2">
        <div class="box">
          <p>{{ projs.englist_name }}</p>
          <div>{{ projs.typename }}</div>
        </div>
      </div>
      <div class="productBox1">
        <div class="box m160">
          <div class="content1">{{ projs.title }}</div>
          <div class="content2">{{ projs.jdesc }}</div>
          <div class="content3">
            {{ projs.desc }}
          </div>
          <div class="content4">
            <img :src="$host + projs.img" alt="" />
          </div>
        </div>
      </div>
      <div class="productBox2">
        <div class="box m160 flexStart">
          <div class="item" v-for="(item, index) in projs_list" :key="index">
            <a>
              <div class="content1">
                {{ item.title }}
              </div>
              <div class="content2">
                <img :src="$host + item.img" alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="productBox3 m80">
        <div class="box">
          <div class="list" v-for="(item, index) in pro_list" :key="index">
            <div class="link m160">
              <div class="boxLeft">
                <img :src="$host + item.img" alt="" />
              </div>
              <div class="boxRight">
                <div class="content1">{{ item.title }}</div>
                <div class="content2">{{ item.jdesc }}</div>
                <div class="content3">
                  <p style="white-space: pre-line">{{ item.desc }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <SidebarLpk :isSidebar="isSidebar" /> -->
  </div>
</template>

<script>
// import SidebarLpk from "@/views/SidebarLpk.vue";
export default {
  name: "Product",
  components: {
    // SidebarLpk,
  },
  data() {
    return {
      isOut: false,
      isHeader: true,
      videoState: true,
      banner: "",
      ppgs: {},
      pp_video: {},
      pro_cate: {},
      pro_list: [],
      projs: {},
      projs_list: [],
      select_nav: [],
    };
  },
  created() {
    this.$http.get("api.php/Webinfo/getgjx").then(
      function (res) {
        var pro_data = JSON.parse(res.data);
        this.banner = pro_data.banner;
        this.ppgs = pro_data.ppgs_info;
        this.pp_video = pro_data.video;
        this.pro_cate = pro_data.pro_list.cate;
        this.pro_list = pro_data.pro_list.list;
        this.projs = pro_data.pro_info;
        this.projs_list = pro_data.pro_info.imgs;
        this.isOut = true;
        const idM = this.$route.query.top;
        this.$nextTick(() => {
          idM && this.$el.querySelector("." + idM).scrollIntoView();
        });
        // console.log(pro_data);
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
    this.$bus.on("productTops", (value) => {
      this.$nextTick(() => {
        this.$el.querySelector("." + value).scrollIntoView();
      });
    });
  },
  methods: {
    videPlay() {
      if (this.$refs.proVideo.paused) {
        this.$refs.proVideo.play();
        this.videoState = false;
      } else {
        this.$refs.proVideo.pause();
        this.videoState = true;
      }
    },
    videHide() {
      this.$refs.proVideo.pause();
      this.videoState = true;
    },
  },
};
</script>

<style scoped lang="less">
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.aft_banner {
  padding-bottom: 33.04%;
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
.productBox {
  .box1 {
    box-sizing: border-box;
    padding: 0 1.45rem;
    font-size: 0.24rem;
    font-family: @albb_b;
    line-height: 0.42rem;
    text-align: justify;
    margin-top: 0.64rem;
  }
  .box2 {
    position: relative;
    margin-top: 0.84rem;
    p {
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: @color;
    }
  }
  .box3 {
    background: @color;
    padding: 0.72rem 0 0.8rem;
    div {
      color: #fff;
      font-size: 0.2rem;
      color: #fff;
      line-height: 0.42rem;
      text-align: justify;
      box-sizing: border-box;
      padding: 0 1.45rem;
    }
  }
}
.proVideo {
  margin-top: 0.6rem;
  .box {
    position: relative;

    .video {
      overflow: hidden;
      span {
        position: absolute;
        top: 0.1rem;
        right: 0.1rem;
        font-size: 0.3rem;
        color: #ddd;
        cursor: pointer;
        z-index: 2;
      }
      video {
        width: 100%;
        height: 83vh;
        object-fit: cover;
        // transform: scale(0.7);
        transition: 0.5s;
        // margin-top: 0.64rem;
        position: relative;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 101%;
        height: 101%;
        object-fit: cover;
      }
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      cursor: pointer;

      img {
        width: 1.35rem;
        cursor: pointer;
        vertical-align: bottom;
      }
      &.active {
        transform: translateX(-50%) translateY(-50%) scale(1);
      }
    }
    &:hover {
      .play {
        // display: block !important;
      }
    }
  }
}
.productBox1 {
  .box {
    text-align: center;
    .content1 {
      margin-top: 0.6rem;
      font-size: 0.56rem;
      font-family: @albb_m;
      color: #333;
    }
    .content2 {
      font-family: @albb_m;
      font-size: 0.36rem;
      color: @color;
      margin-top: 0.12rem;
    }
    .content3 {
      font-size: 0.2rem;
      color: #333;
      line-height: 0.42rem;
      margin-top: 0.42rem;
    }
    .content4 {
      margin-top: 0.64rem;
      img {
        width: 100%;
      }
    }
  }
}
.productBox2 {
  margin-top: 0.6rem;
  .box {
    .item {
      width: 25%;
      a {
        .content1 {
          font-size: 0.24rem;
          line-height: 0.32rem;
          font-family: @albb_b;
          color: #000;
          text-align: center;
        }
        .content2 {
          height: 1.7rem;
          display: flex;
          align-items: center;
          margin-top: 0.7rem;
          img {
            max-height: 100%;
            max-width: 100%;
            margin: auto;
          }
        }
      }
    }
  }
}
.productBox3 {
  .box {
    .list {
      padding: 1.6rem 0;
      &:nth-child(2n-1) {
        background: #f5f5f5;
      }

      .link {
        display: flex;
        justify-content: space-between;
        .boxLeft {
          width: 45%;
          box-sizing: border-box;
          padding: 0 1rem;
          display: flex;
          align-content: center;
          img {
            max-width: 100%;
            display: inline-block;
          }
        }
        .boxRight {
          width: 45%;
          text-align: right;
          .content1 {
            font-size: 0.56rem;
            font-family: @albb_m;
            color: #333;
            margin-top: 0.5rem;
          }
          .content2 {
            font-size: 0.36rem;
            font-family: @albb_m;
            color: @color;
            margin-top: 0.12rem;
          }
          .content3 {
            font-size: 0.2rem;
            margin-top: 0.48rem;
            color: #666;
            line-height: 0.42rem;
          }
        }
      }
      &:nth-child(2n) {
        .link {
          flex-flow: row-reverse;
          .boxRight {
            text-align: left;
          }
        }
      }
    }
  }
}
@media screen and(max-width:768px) {
  .aft_title .box p {
    font-size: 0.56rem;
  }
  .aft_title .box div {
    font-size: 0.36rem;
  }
  .productBox .box1 {
    padding: 0;
  }
  .productBox .box3 div {
    padding: 0;
  }
  .proVideo .box .video video {
    height: 3rem;
  }
  .proVideo .box .play img {
    width: 1.2rem;
  }
  .productBox1 .box .content1 {
    font-size: 0.36rem;
  }
  .productBox1 .box .content2 {
    font-size: 0.3rem;
  }
  .productBox2 .box {
    justify-content: space-between;
    .item {
      width: 49%;
    }
  }
  .productBox3 .box .list {
    padding: 0.6rem 0;
  }
  .productBox3 .box .list .link {
    flex-flow: wrap;
  }
  .productBox3 .box .list .link .boxLeft {
    width: 100%;
  }
  .productBox3 .box .list .link .boxRight {
    width: 100%;
    text-align: left;
  }
  .productBox3 .box .list:nth-child(2n) .link {
    flex-flow: column;
  }
  .productBox3 .box .list .link .boxRight .content1 {
    font-size: 0.32rem;
  }
  .productBox3 .box .list .link .boxRight .content2 {
    font-size: 0.28rem;
  }
  .productBox3 .box .list .link .boxLeft img {
    max-height: 2.8rem;
    object-fit: fill;
  }
}
</style>
